@import "poole";
@import "syntax";
@import "hyde";

.sidebar-nav,
.sidebar-social {
  margin-bottom: 1rem;
}

.sidebar-social a {
  margin-right: .2rem;
}

.sidebar-legal {
  font-size: .625rem;
}

